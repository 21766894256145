



























import { Observer } from "mobx-vue";
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { walletStore } from "@/stores/wallet-store";
import { MysteryBoxViewModel } from "../viewmodels/mystery-box-viewmodel";

@Observer
@Component({
  components: {
    MysteryBoxWithId: () => import("@/modules/mystery-box/components/mystery-box-with-id.vue"),
  },
})
export default class MysteryBoxCard extends Vue {
  @Inject() vm!: MysteryBoxViewModel;
  @Prop() boxId!: string;
  walletStore = walletStore;
}
